<template>
  <div class="user">
    <div class="tit">{{ $t('reg_organizationUser_1') }}</div>
    <div class="user-box">
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        size="small"
        ref="ruleForm"
        label-width="100px"
        label-position="left"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('reg_organizationUser_2')"
          :rules="[
            {
              required: true,
              message: $t('reg_organizationUser_3'),
              trigger: 'blur',
            },
          ]"
          prop="jigou_name"
        >
          <el-input
            v-model="ruleForm.jigou_name"
            :placeholder="$t('reg_organizationUser_4')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_organizationUser_5')"
          :rules="[
            {
              required: true,
              message: $t('reg_organizationUser_6'),
              trigger: 'blur',
            },
          ]"
          prop="username"
        >
          <el-input
            v-model="ruleForm.username"
            :placeholder="$t('reg_organizationUser_7')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_organizationUser_8')"
          :rules="[
            {
              required: true,
              message: $t('reg_organizationUser_9'),
              trigger: 'blur',
            },
          ]"
          prop="surname"
        >
          <el-input
            v-model="ruleForm.surname"
            :placeholder="$t('reg_organizationUser_10')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_organizationUser_11')"
          :rules="[
            {
              required: true,
              message: $t('reg_organizationUser_12'),
              trigger: 'blur',
            },
            {
              type: 'number',
              trigger: 'blur',
              message: $t('手机号格式不正确'),
            },
          ]"
          prop="mobile"
        >
          <el-input
            v-model.number="ruleForm.mobile"
            :placeholder="$t('reg_organizationUser_13')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="addr-box">
      <div class="addr">
        {{ $t('reg_organizationUser_1') }}：
        <el-popover
          placement="top"
          :title="$t('reg_organizationUser_15')"
          width="300"
          trigger="hover"
          :content="$t('reg_organizationUser_16')"
        >
          <i slot="reference" class="el-icon-question"></i>
        </el-popover>
      </div>
      <div class="upload">
        <el-upload
          class="upload-demo"
          ref="uploadimg"
          action="#"
          :http-request="handleRequest"
          :before-remove="beforeRemove"
          multiple
          :limit="10"
          :file-list="fileList"
        >
          <el-button size="small" icon="el-icon-upload2" type="primary"
            >{{ $t('reg_organizationUser_17') }}
          </el-button>
          <div slot="tip" class="el-upload__tip">
            {{ $t('reg_organizationUser_18') }}：PNG, JPEG, PDF
          </div>
        </el-upload>
      </div>
    </div>
    <div class="textCenter">
      <el-button :loading="nextLoading" @click="next" type="primary">
        {{ $t('reg_organizationUser_19') }}
      </el-button>
      <el-button @click="back" type="text">{{
        $t('reg_organizationUser_20')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: localStorage.getItem('sm_email'),
      },
      fileList: [],
      nextLoading: false,
    }
  },
  created() {
    if (localStorage.getItem('sm_userinfo')) {
      this.ruleForm = {
        ...JSON.parse(localStorage.getItem('sm_userinfo')),
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
      }
      if (this.ruleForm.mechanismimage) {
        this.fileList = JSON.parse(this.ruleForm.mechanismimage)
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'Identity' })
    },
    async next() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.fileList.length <= 0) {
            this.$message({
              message: '请上传机构注册页',
              type: 'warning',
            })
            return
          }
          this.ruleForm.mechanismimage = this.fileList
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/register', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.$router.push({ name: 'IdentityVerification' })
            localStorage.setItem(
              'sm_userinfo',
              JSON.stringify(res.data.userinfo)
            )
          }
        }
      })
    },
    // 文件上传
    async handleRequest(param) {
      let formData = new FormData()
      formData.append('file', param.file)
      let res = await this.$axios.post('/api/Common/upload', formData)
      if (res.code === 1) {
        let row = {
          uid: param.file.uid,
          name: param.file.name,
          url: res.data.fullurl,
        }
        this.fileList.push(row)
      }
    },
    beforeRemove(file) {
      let flag = false
      this.$confirm('您确定要移除此文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          flag = true
          for (let i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].uid === file.uid) {
              this.fileList.splice(i, 1)
              return
            }
          }
        })
        .catch(() => {})
      return flag
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 400px;
  margin: 50px auto 0;

  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}

.mobile {
  .el-select {
    width: 120px;
  }

  .el-input {
    width: calc(100% - 130px);
    margin-left: 10px;
  }
}

.upload {
  margin-top: 20px;
}

.textCenter {
  margin-top: 50px;

  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
</style>
