var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('reg_organizationUser_1')))]),_c('div',{staticClass:"user-box"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"size":"small","label-width":"100px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":_vm.$t('reg_organizationUser_2'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_organizationUser_3'),
            trigger: 'blur',
          } ],"prop":"jigou_name"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_organizationUser_4')},model:{value:(_vm.ruleForm.jigou_name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "jigou_name", $$v)},expression:"ruleForm.jigou_name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_organizationUser_5'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_organizationUser_6'),
            trigger: 'blur',
          } ],"prop":"username"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_organizationUser_7')},model:{value:(_vm.ruleForm.username),callback:function ($$v) {_vm.$set(_vm.ruleForm, "username", $$v)},expression:"ruleForm.username"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_organizationUser_8'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_organizationUser_9'),
            trigger: 'blur',
          } ],"prop":"surname"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_organizationUser_10')},model:{value:(_vm.ruleForm.surname),callback:function ($$v) {_vm.$set(_vm.ruleForm, "surname", $$v)},expression:"ruleForm.surname"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_organizationUser_11'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_organizationUser_12'),
            trigger: 'blur',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: _vm.$t('手机号格式不正确'),
          } ],"prop":"mobile"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_organizationUser_13')},model:{value:(_vm.ruleForm.mobile),callback:function ($$v) {_vm.$set(_vm.ruleForm, "mobile", _vm._n($$v))},expression:"ruleForm.mobile"}})],1)],1)],1),_c('div',{staticClass:"addr-box"},[_c('div',{staticClass:"addr"},[_vm._v(" "+_vm._s(_vm.$t('reg_organizationUser_1'))+"： "),_c('el-popover',{attrs:{"placement":"top","title":_vm.$t('reg_organizationUser_15'),"width":"300","trigger":"hover","content":_vm.$t('reg_organizationUser_16')}},[_c('i',{staticClass:"el-icon-question",attrs:{"slot":"reference"},slot:"reference"})])],1),_c('div',{staticClass:"upload"},[_c('el-upload',{ref:"uploadimg",staticClass:"upload-demo",attrs:{"action":"#","http-request":_vm.handleRequest,"before-remove":_vm.beforeRemove,"multiple":"","limit":10,"file-list":_vm.fileList}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-upload2","type":"primary"}},[_vm._v(_vm._s(_vm.$t('reg_organizationUser_17'))+" ")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" "+_vm._s(_vm.$t('reg_organizationUser_18'))+"：PNG, JPEG, PDF ")])],1)],1)]),_c('div',{staticClass:"textCenter"},[_c('el-button',{attrs:{"loading":_vm.nextLoading,"type":"primary"},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('reg_organizationUser_19'))+" ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('reg_organizationUser_20')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }